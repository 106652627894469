import { Show, createMemo } from 'solid-js';
import { Link, Picture } from '@troon/ui';
import { gql } from '../../../../graphql';
import type {
	DestinationPlace,
	DestinationRegion,
	PopularPlaceFragment,
	PopularRegionFragment,
} from '../../../../graphql';

type Props = PopularRegionFragment | PopularPlaceFragment;

export function PopularDestination(props: Props) {
	const normalized = createMemo(() => {
		if ('region' in props && (props as DestinationRegion).region) {
			return {
				hero: props.region.hero?.url,
				url: `/courses/search?regionIds=${props.region.id}&query=${props.region.name}`,
				name: props.region.name,
				facilityCount: props.region.facilityCount,
			};
		}

		const place = (props as DestinationPlace).place;
		const query = new URLSearchParams({
			lat: place.coordinates.latitude.toString(),
			lon: place.coordinates.longitude.toString(),
			query: `${place.name}, ${place.regionName}`,
		});
		return {
			hero: place.hero?.url,
			url: `/courses/search?${query.toString()}`,
			name: `${place.name}, ${place.regionName}`,
			facilityCount: place.facilityCount,
		};
	});
	return (
		<div class="group relative overflow-hidden rounded border border-neutral">
			<div
				// eslint-disable-next-line tailwindcss/no-arbitrary-value
				class="relative aspect-[2/1] overflow-hidden"
			>
				<Show when={normalized().hero}>
					{(url) => (
						<Picture
							src={url()}
							alt=""
							sizes={[[346, 173]]}
							class="absolute inset-0 overflow-hidden bg-center object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
							loading="lazy"
						/>
					)}
				</Show>
			</div>
			<p class="flex flex-col gap-px px-4 py-2">
				<Link href={normalized().url} class="font-semibold after:absolute after:inset-0 hover:text-brand-600">
					{normalized().name}
				</Link>
				<span class="text-sm font-medium text-neutral-800">{normalized().facilityCount} courses</span>
			</p>
		</div>
	);
}

gql(`
fragment PopularRegion on DestinationRegion {
  region {
    id
	  name
	  hero { url }
	  facilityCount
  }
}
fragment PopularPlace on DestinationPlace {
  place {
    name
    regionName
    coordinates {
      latitude
      longitude
    }
		hero { url }
    facilityCount
  }
}`);
