import { createSignal, For, onMount } from 'solid-js';
import { Link } from '@solidjs/meta';
import { getImageUrl } from '@troon/ui';
import { ImageOverlay } from '../image-overlay';
import { getConfigValue } from '../../modules/config';
import type { ParentProps } from 'solid-js';

type Props = ParentProps;
export function HeroVideo(props: Props) {
	const [video, setVideo] = createSignal<HTMLVideoElement>();

	onMount(() => {
		const el = video();
		if (el) {
			// el.addEventListener('playing', () => {
			// 	// Extra hack to remove the poster to force Safari to remove it when playing
			// 	el.removeAttribute('poster');
			// });
			el.muted = true;
			setTimeout(() => {
				try {
					el.play();
				} catch {
					// no-op. Safari in battery saver mode will not allow autoplay or scripting play videos
				}
			}, 16);
		}
	});

	return (
		<div
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class="relative flex max-h-min min-h-[70svh] flex-col items-center overflow-hidden bg-brand md:min-h-[75svh]"
		>
			<Link rel="preload" fetchpriority="high" as="image" href={poster} />
			<video
				ref={setVideo}
				preload="auto"
				poster={poster}
				muted
				autoplay
				playsinline
				loop
				class="absolute size-full object-cover"
			>
				<For each={sources}>
					{(src) => (
						<source
							src={`https://d1lc3bk56q5l6m.cloudfront.net/digital/hero/${src.file}`}
							type={src.type}
							media={src.media}
						/>
					)}
				</For>
			</video>

			<div class="pointer-events-none absolute inset-0 bg-black/30" />
			<div class="pointer-events-none absolute inset-0 bg-gradient-to-b from-black/0 via-black/0 to-black" />
			<div class="z-30 flex w-full grow items-center pb-12 pt-32 text-white">{props.children}</div>
			<ImageOverlay class="absolute -bottom-1" />
		</div>
	);
}

const sources = [
	{ media: '(min-width: 720px)', file: 'hero-480p-vp9-20240916.webm', type: 'video/webm; codecs="vp9"' },
	{ media: '(min-width: 720px)', file: 'hero-480p-vp8-20240916.webm', type: 'video/webm; codecs="vp8"' },
	{ media: '(min-width: 720px)', file: 'hero-480p-20240916.mp4', type: 'video/mp4' },
	{ media: '(max-width: 720px)', file: 'hero-mobile-vp9-20240916.webm', type: 'video/webm; codecs="vp9"' },
	{ media: '(max-width: 720px)', file: 'hero-mobile-vp8-20240916.webm', type: 'video/webm; codecs="vp8"' },
	{ media: '(max-width: 720px)', file: 'hero-mobile-20240916.mp4', type: 'video/mp4' },
];

const poster = getImageUrl(`${getConfigValue('IMAGE_HOST')}/digital/hero/hero-480p-20240926.jpg`, {
	width: 720,
	height: 480,
	crop: 'center',
	mode: 'crop',
});
